"use client";

import { Button } from "@/ui/Button";
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from "@/ui/Sheet";
import { useState } from "react";
import Content from "./Content";
import { IoMenu } from "react-icons/io5";

export function MobileSidebar() {
  const [open, setOpen] = useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="p-0 h-fit !text-foreground rtl:rotate-180 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
        >
          <IoMenu className="w-6 h-6" />

          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="auto" className="!p-0 z-[100]">
        <SheetTitle className="sr-only">navigation links for mobile</SheetTitle>
        <Content setIsMobileSidOpen={setOpen} />
      </SheetContent>
    </Sheet>
  );
}
