"use client";

import { Badge } from "@/components/ui/Badge";
import { Button, buttonVariants } from "@/components/ui/Button";
import { Checkbox } from "@/components/ui/Checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/Dialog";
import useCurrentUserRole from "@/hooks/useCurrentUserRole";
import usePost from "@/hooks/usePost";
import { paymentPercentageSchema } from "@/schemas/paymentPercentage";
import {
  activePaymentPercentageForUser,
  disablePaymentPercentageForUser,
} from "@/services/paymentPercentage";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { toast } from "sonner";

export const paymentPercentageDays = [
  { name: "saturday", value: 6 },
  { name: "sunday", value: 7 },
  { name: "monday", value: 1 },
  { name: "tuesday", value: 2 },
  { name: "wednesday", value: 3 },
  { name: "thursday", value: 4 },
];

const PaymentPercentageDialog = ({
  username,
  isEnabled,
  userPaymentPercentage,
  userPaymentDay,
  refetch,
}) => {
  const { t } = useTranslation(["transactions", "common"]);
  const [open, setOpen] = useState(false);

  const { handlePost: handleActivateEdit, isLoading: isActivating } = usePost(
    handleActivateEditCallback,
    [],
    { showErrorToast: true }
  );
  const { handlePost: handleDisActiveFeature, isLoading: isDisabling } =
    usePost(handleDisActiveCallback, [], { showErrorToast: true });

  const isLoading = isActivating || isDisabling;

  const paymentDayName = paymentPercentageDays.find(
    d => d.value == userPaymentDay
  )?.name;

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(paymentPercentageSchema),
    defaultValues: {
      day: isEnabled ? userPaymentDay : null,
    },
  });

  const selectedDay = watch("day");

  useEffect(() => {
    if (!isEnabled) return;
    setValue("day", userPaymentDay);
  }, [userPaymentDay, setValue, isEnabled]);

  async function handleActivateEditCallback(data) {
    try {
      await activePaymentPercentageForUser(username, data.day);
      refetch();
      reset();
      setOpen(false);
      toast.success(
        t("payment_percentage_dialog.success_message", {
          action: isEnabled
            ? t("payment_percentage_dialog.edit")
            : t("payment_percentage_dialog.activate"),
        })
      );
    } catch (error) {
      toast.error(t("payment_percentage_dialog.activate_error_message"));
    }
  }

  async function handleDisActiveCallback() {
    try {
      await disablePaymentPercentageForUser(username);
      refetch();
      reset();
      setOpen(false);
      toast.success(t("payment_percentage_dialog.deactivate_success_message"));
    } catch (error) {
      toast.error(t("payment_percentage_dialog.deactivate_error_message"));
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <div className="flex items-center gap-2">
        {isEnabled && (
          <>
            {typeof userPaymentPercentage === "number" ? (
              <Badge
                variant={userPaymentPercentage > 90 ? "success" : "destructive"}
              >
                {userPaymentPercentage} %
              </Badge>
            ) : (
              <span>
                {t("payment_percentage_dialog.starting_from_next", {
                  day: t(`days_of_week.${paymentDayName}`, { ns: "common" }),
                })}
              </span>
            )}
          </>
        )}

        <DialogTrigger
          className={buttonVariants({
            variant: isEnabled ? "outline" : "primary",
            className: isEnabled && "h-8 w-8 flex-shrink-0 !p-0 !px-0",
            size: !isEnabled ? "sm" : "",
          })}
          aria-label={
            isEnabled
              ? t("payment_percentage_dialog.edit_payment_percentage")
              : t("payment_percentage_dialog.activate_payment_percentage")
          }
        >
          {isEnabled ? (
            <MdEdit className="text-[1rem]" />
          ) : (
            t("payment_percentage_dialog.activate")
          )}
        </DialogTrigger>
      </div>
      <DialogContent>
        <DialogHeader className="sm:text-start">
          <DialogTitle className="leading-6">
            {t("payment_percentage_dialog.title", {
              action: isEnabled
                ? t("payment_percentage_dialog.edit")
                : t("payment_percentage_dialog.activate"),
              username,
            })}
          </DialogTitle>
          <DialogDescription>
            {t("payment_percentage_dialog.description")}
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit(handleActivateEdit)} className="w-full">
          <span className="block mb-2 font-medium">
            {t("payment_percentage_dialog.select_weekly_payment_day")}
          </span>

          <div className="space-y-2">
            {paymentPercentageDays.map(day => (
              <DayCheckbox
                key={day.value}
                day={day}
                selectedDay={selectedDay}
                setValue={setValue}
              />
            ))}
            {errors.day && (
              <p className="text-red-500 text-sm pt-1 block">
                {errors.day.message}
              </p>
            )}
          </div>

          <DialogFooter className="mt-8">
            {isEnabled && (
              <Button
                className="sm:me-auto"
                onClick={handleDisActiveFeature}
                variant="destructive"
                type="button"
              >
                {t("payment_percentage_dialog.deactivate")}
              </Button>
            )}
            <DialogClose asChild>
              <Button type="reset" variant="outline">
                {t("payment_percentage_dialog.cancel")}
              </Button>
            </DialogClose>
            <Button isLoading={isLoading} type="submit">
              {isEnabled
                ? t("payment_percentage_dialog.edit")
                : t("payment_percentage_dialog.confirm")}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const DayCheckbox = ({ day, selectedDay, setValue, ...props }) => {
  const { t } = useTranslation("common");

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        id={`check-${day.value}`}
        onCheckedChange={() => setValue("day", day.value)}
        checked={day.value == selectedDay}
        {...props}
      />
      <label
        className="text-sm leading-none font-normal"
        htmlFor={`check-${day.value}`}
      >
        {t(`days_of_week.${day.name}`)}
      </label>
    </div>
  );
};

export default PaymentPercentageDialog;
