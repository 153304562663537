import Logo from "@/components/Logo";
import { AvatarWithFallback } from "@/components/ui/Avatar";
import { Badge } from "@/components/ui/Badge";
import { cn } from "@/lib/utils";
import useStore from "@/store";
import { getUserAvatarFallback } from "@/utils/getUserAvatarFallback";
import Link from "next/link";
import React from "react";

const MobilSecondNavbar = () => {
  const user = useStore(state => state.currentUser?.user);
  const userRole = user?.roles[0]?.name;
  const hasFirstName = !!user.fname;
  const avatarFallback = getUserAvatarFallback(
    user.username,
    user.fname,
    user.lname
  );
  const title = hasFirstName ? user.fname + " " + user.lname : user.username;

  return (
    <div className="md:hidden h-12 px-4 flex items-center justify-between bg-background">
      <Logo className="w-[45%] max-w-[180px]" />

      <Link passHref href="/profile">
        <button
          dir="ltr"
          className="overflow-hidden flex items-center gap-2 transition-[margin] duration-300 ease-in-out hover:bg-muted rounded-lg px-2 h-full"
        >
          <AvatarWithFallback
            className="w-7 h-7 uppercase outline outline-offset-2 outline-1 outline-primary "
            fallback={avatarFallback}
            src={user?.avatar}
          />
          <div className="overflow-hidden flex flex-col items-start transition-[width,opacity] duration-300 ease-in-out">
            <span className="w-full text-xs text-start capitalize font-medium truncate">
              {title}
            </span>

            <Badge size="sm" className="w-fit text-[0.6rem]" variant="outline">
              {userRole}
            </Badge>
          </div>
        </button>
      </Link>
    </div>
  );
};

export default MobilSecondNavbar;
