import { AvatarWithFallback } from "@/components/ui/Avatar";
import { Badge } from "@/components/ui/Badge";
import useClickOutside from "@/hooks/useClickOutside";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";

const MenuTrigger = ({ user, onClick, isSmall, isMenuOpen }) => {
  const userRole = user?.roles[0]?.name;
  const hasFirstName = !!user.fname;
  const avatarFallback = hasFirstName
    ? `${user.fname[0]}${user.lname[0]}`
    : user.username.slice(0, 2);
  const title = hasFirstName ? user.fname + " " + user.lname : user.username;

  return (
    <button
      onClick={onClick}
      dir="ltr"
      className={cn(
        "w-full flex gap-2 md:gap-4 transition-[margin] duration-300 ease-in-out",
        isSmall && !isMenuOpen && "mr-[13px]",
        isMenuOpen && "ps-6"
      )}
    >
      <AvatarWithFallback
        className="w-12 h-12 uppercase outline outline-offset-2 outline-1 outline-primary"
        fallbackClassName={cn(
          "bg-secondary-foreground text-secondary dark:bg-secondary dark:text-secondary-foreground",
          isMenuOpen && "bg-secondary-foreground text-secondary"
        )}
        fallback={avatarFallback}
        src={user?.avatar}
      />
      <div
        className={cn(
          "flex-1 overflow-hidden flex flex-col transition-[width,opacity] duration-300 ease-in-out",
          isSmall && !isMenuOpen ? "opacity-0 w-0" : "opacity-1"
        )}
      >
        <div className="flex-1 flex items-center gap-1 ms-1">
          <span className="flex-1 text-start pe-3 capitalize font-medium truncate">
            {title}
          </span>
          <IoIosArrowDown className="h-[18px] w-[18px] me-2" />
        </div>

        <Badge
          className={cn(
            "w-fit border-secondary-foreground",
            isMenuOpen ? "dark:border-foreground/50" : "dark:border-none"
          )}
          variant={isMenuOpen ? "outline" : "secondary"}
        >
          {userRole}
        </Badge>
      </div>
    </button>
  );
};

const ProfileMenu = ({ user, isSmall, setIsMobileSidOpen = v => {} }) => {
  const { t } = useTranslation("layout");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const { pathname } = useRouter();
  useClickOutside(menuRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  const handleClick = () => {
    setIsMenuOpen(false);
    setIsMobileSidOpen(false);
  };

  const menuLinks = [
    {
      title: t("logout"),
      path: "/logout",
    },
    {
      title: t("security"),
      path: "/security",
    },
    {
      title: t("profile"),
      path: "/profile",
    },
  ];

  return (
    <div
      ref={menuRef}
      className="w-full border-t border-t-border absolute bottom-0 inset-x-0 px-[24px] py-[16px] bg-muted dark:bg-background"
    >
      <MenuTrigger
        isSmall={isSmall}
        isMenuOpen={false}
        onClick={() => setIsMenuOpen(prev => !prev)}
        user={user}
      />

      <div
        className={cn(
          "absolute bottom-0 inset-x-0 bg-background dark:bg-muted w-full md:min-w-[290px] py-[20px] flex flex-col gap-4 translate-y-[100%] transition-[transform] duration-300 ease-in-out",
          isMenuOpen && "translate-y-[0%]"
        )}
      >
        <div className="flex flex-col ms-[38px] text-muted-foreground">
          {menuLinks.map(link => (
            <Link key={link.path} passHref href={link.path}>
              <a
                onClick={handleClick}
                className={cn(
                  "hover:text-foreground transition-[color] py-2 first-letter:capitalize",
                  pathname.startsWith(link.path) && "font-semibold"
                )}
              >
                {link.title}
              </a>
            </Link>
          ))}
        </div>
        <MenuTrigger
          isSmall={isSmall}
          isMenuOpen={true}
          onClick={() => setIsMenuOpen(prev => !prev)}
          user={user}
        />
      </div>
    </div>
  );
};

export default ProfileMenu;
